//引入刚才的http.js文件
import https from './http.js';
//设置个对象，就不用一个个暴露了，直接暴露对象
let apiFun = {};

//获取状态
apiFun.address = (params) => {
	return https.get('/address', params)
}

// 登录
apiFun.login = (params) => {
	return https.post('/login', params)
}
//超级用户注册
apiFun.enrollsuper = (params) => {
	return https.post('/enrollsuper', params)
}
//用户注册
apiFun.enroll = (params) => {
	return https.post('/enroll', params)
}
//获取所有网络列表
apiFun.networksall = (params) => {
	return https.post('/networksall', params)
}
//获取当前用户网络列表
apiFun.networks = (params) => {
	return https.post('/networks', params)
}
//获取当前用户项目列表
apiFun.project = (params) => {
	return https.post('/project', params)
}
//获取网络详细信息
apiFun.getnetworksDetail = (params) => {
	return https.post('/getnetworksDetail', params)
}
//获取网络信息
apiFun.networkchange = (params) => {
	return https.post('/networkchange', params)
}
//修改网络信息
apiFun.networkDetail = (params) => {
	return https.post('/networkDetail', params)
}
//修改设备数量
apiFun.changenumber = (params) => {
	return https.post('/changenumber', params)
}
//修改网络详细信息
apiFun.networksDetail = (params) => {
	return https.post('/networksDetail', params)
}
//获取成员信息
apiFun.getnetworksMember = (params) => {
	return https.post('/getnetworksMember', params)
}
//获取成员详细信息
apiFun.getnetworksMemberdetail = (params) => {
	return https.post('/getnetworksMemberdetail', params)
}
//获取成员peer
apiFun.peer = (params) => {
	return https.post('/peer', params)
}
//修改成员详细信息
apiFun.memberDetail = (params) => {
	return https.post('/memberDetail', params)
}
//添加网络
apiFun.addnet = (params) => {
	return https.post('/addnet', params)
}
//删除网络
apiFun.networksDelete = (params) => {
	return https.post('/networksDelete', params)
}
//删除网络
apiFun.deleteuser = (params) => {
	return https.post('/deleteuser', params)
}
//修改设备名称
apiFun.equipmentname = (params) => {
	return https.post('/equipmentname', params)
}
//修改项目
apiFun.changeproject = (params) => {
	return https.post('/changeproject', params)
}
//获取设备名称
apiFun.getequipmentname = (params) => {
	return https.post('/getequipmentname', params)
}
//删除成员
apiFun.memberDelete = (params) => {
	return https.post('/memberDelete', params)
}
//获取所有项目
apiFun.getproject = (params) => {
	return https.post('/getproject', params)
}
//删除项目
apiFun.deleteproject = (params) => {
	return https.post('/deleteproject', params)
}
//手机注册获取短信
apiFun.mobile = (params) => {
	return https.post('/mobile', params)
}
//手机登录获取短信
apiFun.mobilelogin = (params) => {
	return https.post('/mobilelogin', params)
}

//忘记密码获取短信
apiFun.forgot = (params) => {
	return https.post('/forgot', params)
}
//忘记密码下一页
apiFun.pwdnextpage = (params) => {
	return https.post('/pwdnextpage', params)
}
//修改密码
apiFun.changepwd = (params) => {
	return https.post('/changepwd', params)
}
//查询用户信息
apiFun.selectuser = (params) => {
	return https.post('/selectuser', params)
}
//头像功能
apiFun.head = (params) => {
	return https.post('/head', params)
}
//设备图片功能
apiFun.equipmentimg = (params) => {
	return https.post('/equipmentimg', params)
}
//修改头像信息信息
apiFun.updathead = (params) => {
	return https.post('/updathead', params)
}
//修改设备图片信息
apiFun.updatequipment = (params) => {
	return https.post('/updatequipment', params)
}
//修改用户信息
apiFun.updateuser = (params) => {
	return https.post('/updateuser', params)
}
//修改手机号
apiFun.changemobile = (params) => {
	return https.post('/changemobile', params)
}
//添加项目
apiFun.addproject = (params) => {
	return https.post('/addproject', params)
}
//修改邮箱
apiFun.setemail = (params) => {
	return https.post('/setemail', params)
}
//获取设备地址
apiFun.getequipment = (params) => {
	return https.post('/getequipment', params)
}
//获取设备地址
apiFun.alipay = (params) => {
	return https.post('/alipay', params)
}
//获取设备地址
apiFun.orderlist = (params) => {
	return https.post('/orderlist', params)
}

export default apiFun;