<template>
    <div style="height: 100vh;width: 100vw;">
        <!-- 页头 -->
        <nav-header2 style="position: absolute;z-index: 999;"></nav-header2>
        <div style="height: 50px;"></div>
        <div style="display: flex;height: calc(100% - 50px);width: 100%;">
            <!-- 侧边栏 -->
            <side-bar></side-bar>
            <div style="flex: 1;padding: 10px;background-color: #e4e7ed;display: flex;">
                <div class="content-box">
                    <div class="left">
                        <div class="equipmentbox">
                            <div
                                style="height: 180px;display: flex;flex-direction: column;justify-content: space-around;flex-shrink: 0;">
                                <div class="select">
                                    <div>
                                        <div style="color: #afb5bd;">行政区划：</div>
                                        <el-select v-model="province" placeholder="请选择">
                                            <el-option label="全国" value="全国"></el-option>
                                            <el-option label="北京市" value="北京市"></el-option>
                                            <el-option label="天津市" value="天津市"></el-option>
                                            <el-option label="上海市" value="上海市"></el-option>
                                            <el-option label="重庆市" value="重庆市"></el-option>
                                            <el-option label="河北省" value="河北省"></el-option>
                                            <el-option label="山西省" value="山西省"></el-option>
                                            <el-option label="辽宁省" value="辽宁省"></el-option>
                                            <el-option label="吉林省" value="吉林省"></el-option>
                                            <el-option label="黑龙江省" value="黑龙江省"></el-option>
                                            <el-option label="福建省" value="福建省"></el-option>
                                            <el-option label="江西省" value="江西省"></el-option>
                                            <el-option label="江苏省" value="江苏省"></el-option>
                                            <el-option label="浙江省" value="浙江省"></el-option>
                                            <el-option label="安徽省" value="安徽省"></el-option>
                                            <el-option label="山东省" value="山东省"></el-option>
                                            <el-option label="河南省" value="河南省"></el-option>
                                            <el-option label="湖北省" value="湖北省"></el-option>
                                            <el-option label="湖南省" value="湖南省"></el-option>
                                            <el-option label="广东省" value="广东省"></el-option>
                                            <el-option label="海南省" value="海南省"></el-option>
                                            <el-option label="四川省" value="四川省"></el-option>
                                            <el-option label="贵州省" value="贵州省"></el-option>
                                            <el-option label="云南省" value="云南省"></el-option>
                                            <el-option label="陕西省" value="陕西省"></el-option>
                                            <el-option label="甘肃省" value="甘肃省"></el-option>
                                            <el-option label="青海省" value="青海省"></el-option>
                                            <el-option label="台湾省" value="台湾省"></el-option>
                                            <el-option label="内蒙古自治区" value="内蒙古自治区"></el-option>
                                            <el-option label="广西壮族自治区" value="广西壮族自治区"></el-option>
                                            <el-option label="西藏自治区" value="西藏自治区"></el-option>
                                            <el-option label="宁夏回族自治区" value="宁夏回族自治区"></el-option>
                                            <el-option label="新疆维吾尔族自治区" value="新疆维吾尔族自治区"></el-option>
                                            <el-option label="香港特别行政区" value="香港特别行政区"></el-option>
                                            <el-option label="澳门特别行政区" value="澳门特别行政区"></el-option>
                                        </el-select>
                                    </div>
                                    <div>
                                        <div style="color: #afb5bd;">项目类型：</div>
                                        <el-select v-model="project" placeholder="请选择">
                                            <el-option v-for="i in projectList" :key="i.id" :label="i.pjname"
                                                :value="i.pjname"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="input">
                                    <div class="iconfont icon-sousuo inputimg"></div>
                                    <el-input v-model="project2" placeholder="请输入项目名称">
                                    </el-input>
                                </div>
                                <div style="display: flex;justify-content:space-around;">
                                    <div :style="{ borderColor: btnstate == 'online' ? '#012f55' : '#DCDFE6' }"
                                        class="equipmentbtn" @click="btnstate = 'online'">
                                        在线设备
                                        <div v-if="btnstate == 'online'" class="check iconfont icon-ziyuan"></div>
                                    </div>
                                    <div :style="{ borderColor: btnstate == 'online' ? '#DCDFE6' : '#012f55' }"
                                        class="equipmentbtn" @click="btnstate = 'offline'">
                                        离线设备
                                        <div v-if="btnstate == 'offline'" class="check iconfont icon-ziyuan"></div>
                                    </div>
                                </div>
                                <div class="searchbtn" @click="search()">
                                    <div class="iconfont icon-sousuo"></div>
                                    <div>查询</div>
                                </div>
                            </div>
                            <div style="height: calc(100% - 180px);">
                                <div class="title">
                                    <div>查询结果</div>
                                    <div>当前 {{ equipmentList.length }}/{{ equipmentlength }}</div>
                                </div>
                                <div style="overflow-y: auto;height: calc(100% - 30px);">
                                    <div class="equipmentListbox" v-for="i in equipmentList" :key="i.creationTime"
                                        @click="page2(i.net)">
                                        <div
                                            style="width: 20%;display: flex;align-items: center;justify-content: center;">
                                            <div>
                                                <img style="width: 15px;height: 15px;" src="../assets/img/state.png">
                                            </div>
                                        </div>
                                        <div>
                                            <div style="color: #012f55;font-size: 14px;">
                                                {{ i.name }}
                                            </div>
                                            <div style="color: #b0b7bf;font-size: 12px;">
                                                {{ i.desc ? i.desc : '暂无描述' }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="echartbox">
                            <div id="echarts">
                            </div>
                            <div style="margin-left: 38px;color: #012f55;">
                                <div class="echarttitle">设备统计</div>
                                <div style="display: flex;align-items: center;">
                                    <span class="iconfont icon-lvdeng" style="color: #3b54ec;font-size: 30px;"></span>
                                    <span>在线设备</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>{{ online }} 台</span>
                                </div>
                                <div style="display: flex;align-items: center;">
                                    <span class="iconfont icon-lvdeng" style="color: #fc755f;font-size: 30px;"></span>
                                    <span>离线设备</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>{{ offline }} 台</span>
                                </div>
                            </div>
                        </div>
                        <div class="netbg">
                            <div class="bgtitle">
                                {{ netlength }}
                            </div>
                            <div class="bgson">网络数量</div>
                        </div>
                        <div class="equipmentbg">
                            <div class="bgtitle">{{ equipmentlength }}</div>
                            <div class="bgson">设备数量</div>
                        </div>
                    </div>
                    <div id="mainaaa">
                        <index-map :changeprovince="changeprovince"></index-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            address: '',
            isuper: '',
            netlength: '',
            equipmentdata: [],
            equipmentList: [],
            projectList: [],
            equipmentlength: 0,
            online: 0,
            offline: 0,
            percent: '',
            province: '全国',
            changeprovince: '全国',
            project: '全部项目',
            project2: '',
            btnstate: 'online',
        }
    },
    created() {
        this.$js.token();
        sessionStorage.setItem('tabid', '1');
        sessionStorage.setItem('path', '/networksindex');
        sessionStorage.setItem('netpage', '1');
        this.getaddress()
        this.networks()
        this.getproject()
    },
    methods: {
        //echart
        getRenderer() {
            // 基于准备好的dom，初始化echarts实例
            let EChart = this.$echarts.init(document.getElementById("echarts"));
            // 配置参数
            let config = {
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        name: '设备统计',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            position: 'center',
                            formatter: [
                                '{blue| ' + this.percent + '%}',
                                '{red|在线率}'
                            ].join('\n'),
                            rich: {
                                blue: {
                                    color: '#012f55',
                                    fontSize: 30,
                                    padding: [3, 10, 10, 5],
                                },
                                red: {
                                    color: '#9dabc3',
                                    fontSize: 16,
                                    fontweight: 'bold',
                                }
                            },
                            silent: true,
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: this.online, name: '在线', itemStyle: { color: '#3b54ec' } },
                            { value: this.offline, name: '离线', itemStyle: { color: '#fc755f' } },
                        ]
                    }
                ]
            };
            // 设置参数
            EChart.setOption(config, true);
        },
        page() {
            this.$js.openpages('/Networks');
        },
        //设备页跳转
        page2(id) {
            this.$js.openpages('/Equipment', { id });
            sessionStorage.setItem('tabid', '21');
            sessionStorage.setItem('path', '/Networks');
        },
        //获取状态
        async getaddress() {
            let res = await this.$apiFun.address();
            this.address = res

        },
        //获取所有项目列表
        async getproject(){
            let data = JSON.parse(sessionStorage.getItem('user'));
            let userid = data.id
            let res = await this.$apiFun.project({ userid });
            this.projectList = res
            this.projectList.unshift({pjname:'全部项目'})
        },
        //获取所有网络列表
        async networks() {
            let data = JSON.parse(sessionStorage.getItem('user'));
            let userid = data.id
            let issuper = data.issuper
            let res = await this.$apiFun.networks({ userid, issuper });
            this.networkList = res
            this.netlength = this.networkList.length
            // 获取成员
            const promises = this.networkList.map(async (item) => {
                let id = item.nwid;
                let res = await this.$apiFun.getnetworksMember({ id });
                this.equipmentdata = this.equipmentdata.concat(res);
                this.equipmentlength += res.length;
                await Promise.all(res.map(async (item) => {
                    let res2 = await this.$apiFun.getequipmentname({ mid: item.id, id });
                    if (item.state == '在线') {
                        this.online++;
                    } else {
                        this.offline++;
                    }
                }));
            });
            Promise.all(promises)
                .then(() => {
                    this.percent = Math.round((this.online / this.equipmentlength).toFixed(2) * 100);
                    this.getRenderer()
                })
        },
        search() {
            this.equipmentList = []
            if (this.btnstate == 'online') {
                this.equipmentdata.forEach(async (item) => {
                    if (item.state == '在线') {
                        let mid = item.id
                        let id = item.nwid
                        let res = await this.$apiFun.getequipmentname({ mid, id });
                        if ((res.province == this.province || this.province == '全国') && ((res.project == this.project || this.project == '全部项目') && (res.project == this.project2 || this.project2 == ''))) {
                            this.equipmentList.push(res);
                        }
                    }
                });
            } else {
                this.equipmentdata.forEach(async (item) => {
                    if (item.state != '在线') {
                        let mid = item.id
                        let id = item.nwid
                        let res = await this.$apiFun.getequipmentname({ mid, id });
                        if ((res.province == this.province || this.province == '全国') && ((res.project == this.project || this.project == '全部项目') && (res.project == this.project2 || this.project2 == ''))) {
                            this.equipmentList.push(res);
                        }
                    }
                });
            }
            this.changeprovince = this.province
        }
    }
}
</script>

<style scoped>
.content-box {
    flex: 1;
    position: relative;
    padding: 0 1.1%;
    background-color: white;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.title {
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    background-color: #4f9cfc;
    color: white;
}

.left {
    position: absolute;
    z-index: 999;
    height: 100%;
    margin-left: -1.1%;
    display: flex;
}

.right {
    width: calc(100% - 15.7vw);
    max-width: calc(100% - 243.4px);
    height: 160px;
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    left: max(15.7vw, 243.4px);
    bottom: 0px;
}

#mainaaa {
    position: absolute;
    z-index: 998;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}


.equipmentbox {
    width: 15.7vw;
    min-width: 243.4px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.netbg {
    background-image: url(../assets/img/netbg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    width: 450px;
    height: 150px;
    margin-bottom: 10px;
    margin-left: max(calc((100% - 1350px)/4),0px);
    margin-right: max(calc((100% - 1350px)/4),0px);
}

.bgtitle {
    width: 100%;
    font-size: 40px;
    text-align: center;
    margin: 40px 0px 20px;
    color: white;
}

.bgson {
    width: 100%;
    text-align: center;
    color: white;
}

.equipmentbg {
    background-image: url(../assets/img/eqbg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    width: 450px;
    height: 150px;
    margin-bottom: 10px;
}

.equipmentbtn {
    position: relative;
    font-size: 14px;
    letter-spacing: 1px;
    width: fit-content;
    padding: 3px 30px;
    color: #012f55;
    border: 1px solid #012f55;
    cursor: pointer;
}

.check {
    position: absolute;
    z-index: 9;
    background-color: white;
    right: -5px;
    top: -8px;
}

.searchbtn {
    display: flex;
    justify-content: center;
    font-size: 14px;
    letter-spacing: 2px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    color: white;
    background-color: #f7b53d;
    border-radius: 5px;
    margin: 0 auto;
    cursor: pointer;
}

.equipmentListbox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
}

.equipmentListbox:hover {
    cursor: pointer;
    background-color: #dfe7f5 !important;
}

.equipmentListbox:nth-child(odd) {
    background-color: #f2f5fa;
}

.select {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.input {
    position: relative;
    padding: 0 14px;
}

.inputimg {
    position: absolute;
    z-index: 999;
    top: 7px;
    left: 25px;
    color: #E1E2D7;
}

.echartbox {
    background-color: white;
    width: 450px;
    height: 150px;
    margin-bottom: 10px;
    display: flex;
}

.echarttitle {
    font-size: 18px;
    margin: 15px 0px;
}

#echarts {
    width: 200px;
    height: 200px;
    margin: -25px 0px;
}

.input:deep(.el-input__inner) {
    height: 30px;
    line-height: 30px;
    padding-left: 29px;
}

.input:deep(.el-input__inner:hover) {
    border-color: #012f55;
}

.input:deep(.el-input__inner:focus) {
    border-color: #012f55;
}

.select:deep(.el-input--suffix) {
    width: 122px;
}

.select:deep(.el-input__inner) {
    color: #012f55;
    height: 30px;
    line-height: 30px;
}

.select:deep(.el-input__icon) {
    line-height: 30px;
}

.select:deep(.el-input__inner:hover) {
    border-color: #012f55;
}

.select:deep(.el-select .el-input__inner:focus) {
    border-color: #012f55;
}

.select:deep(.el-select .el-input.is-focus .el-input__inner) {
    border-color: #012f55;
}
</style>